<template>
  <div>
    <div
      class="card mb-3"
      style="width: 100%"
      v-loading="loading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="card-header bg-light">
        <h3 class="title fw-normal mb-0 p-2">Chat Bot Integrations</h3>
      </div>

      <!-- No Data Section -->
      <el-row :gutter="12" v-if="!showTextArea">
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          class="table-container mt-20 ml-3 mb-10"
        >
          <div class="no-data">
            <img src="@/assets/img/nodata2.svg" alt="No Data" />
            <p>
              There are no Chat Bots currently available. Click 'Add New Chat
              Bot' to set up an integration.
            </p>
            <div>
              <el-button type="primary" @click="createNewChatBot"
                >Add New Chat Bot</el-button
              >
            </div>
          </div>
        </el-col>
      </el-row>

      <!-- Text Area Section with Buttons (Conditional Rendering) -->
      <el-row :gutter="12" v-if="showTextArea">
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          class="table-container mt-10"
        >
          <!-- Text Area with Scroll -->
          <el-input
            type="textarea"
            rows="10"
            placeholder="Enter the details for the Chat Bot"
            v-model="newChatBotDetails"
            class="scrollable-textarea"
          ></el-input>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          class="button-container mt-10"
        >
          <!-- Conditional Buttons for Update/Create and Delete -->
          <el-button type="primary" v-if="editing" @click="updateChatBot"
            >Update</el-button
          >
          <el-button type="danger" v-if="editing" @click="deleteChatBot"
            >Delete</el-button
          >
          <el-button type="primary" v-else @click="submitNewChatBot"
            >Create</el-button
          >
          <el-button v-if="!editing && showTextArea" @click="cancelEdit"
            >Back</el-button
          >
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { postAPICall } from "@/helpers/httpHelper";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      loadingText: "Loading ...",
      botAccounts: [], // List of existing bots
      showTextArea: false, // Manage text area visibility
      newChatBotDetails: "", // Holds the details for the new Chat Bot or selected bot
      editing: false, // Flag to track if we're editing an existing bot
      selectedBotId: null, // Holds the ID of the selected bot for editing
    };
  },
  async mounted() {
    this.getBotAccounts();
  },
  computed: {
    ...mapGetters("auth", [
      "getActiveWorkspace",
      "getAuthenticatedUser",
      "getWorkspaceList",
    ]),
  },
  methods: {
    async getBotAccounts() {
      this.loading = true;
      this.loadingText = "Loading Chat Bots ...";
      try {
        let response = await postAPICall("GET", "/bot/get-Bot");

        if (response && response.success) {
          this.botAccounts = response.data.chatBotDetails || [];

          if (this.botAccounts.length > 0) {
            this.showTextArea = true;
            this.newChatBotDetails = this.botAccounts; // Set text area with details of the first bot
            this.selectedBotId = response.data._id; // Set the selected bot ID
            this.editing = true;
          } else {
            this.showTextArea = false; // No bot accounts, so hide the text area
          }
        } else {
          this.$message.error("Failed to fetch Chat Bots: " + response.message);
        }
      } catch (error) {
        console.error(error);
        // this.$message.error("An error occurred while fetching Chat Bots.");
      } finally {
        this.loading = false;
      }
    },

    createNewChatBot() {
      this.showTextArea = true;
      this.newChatBotDetails = ""; // Clear input for new bot
      this.editing = false; // Set editing mode to false
    },

    async submitNewChatBot() {
      if (!this.newChatBotDetails.trim()) {
        this.$message.error("Please enter the details for the new Chat Bot");
        return;
      }

      // Show confirmation dialog before proceeding
      this.$confirm(
        "We're creating the new chatbot configurations and will reload your workspace. Please wait a moment",
        "Confirmation",
        {
          type: "warning",
        }
      )
        .then(async () => {
          const data = {
            chatBotDetails: this.newChatBotDetails,
            workspace: this.getActiveWorkspace,
            userId: this.getAuthenticatedUser._id,
          };

          this.loading = true;
          this.loadingText = "Creating new Chat Bot...";

          try {
            let response = await postAPICall("POST", "/bot/create", data);
            if (response && response.success) {
              this.$message.success("Chat Bot created successfully!");

              this.getBotAccounts();

              // Reload the current page
              window.location.reload();
            } else {
              this.$message.error(
                "Failed to create Chat Bot: " + response.message
              );
            }
          } catch (error) {
            console.error(error);
            this.$message.error(
              "An error occurred while creating the Chat Bot."
            );
          } finally {
            this.loading = false;
          }
        })
        .catch(() => {
          // Handle cancellation of the creation action
          this.$message.info("Creation cancelled.");
        });
    },

    editChatBot(bot) {
      this.showTextArea = true;
      this.newChatBotDetails = bot.details; // Populate textarea with bot details
      this.selectedBotId = bot._id; // Store selected bot ID
      this.editing = true; // Set editing mode to true
    },

    async updateChatBot() {
      if (!this.newChatBotDetails.trim()) {
        this.$message.error("Please enter the details for the Chat Bot");
        return;
      }

      // Show confirmation dialog before proceeding
      this.$confirm(
        "Your chatbot settings have been updated. We're applying the new configurations and will reload your workspace. Please wait a moment",
        "Confirmation",
        {
          type: "warning",
        }
      )
        .then(async () => {
          const data = {
            chatBotDetails: this.newChatBotDetails,
            workspace: this.getActiveWorkspace,
            userId: this.getAuthenticatedUser._id,
            botId: this.selectedBotId, // Send the selected bot ID
          };

          this.loading = true;
          this.loadingText = "Updating Chat Bot...";

          try {
            let response = await postAPICall(
              "PUT",
              `/bot/update/${this.selectedBotId}`,
              data
            );
            if (response && response.success) {
              this.$message.success("Chat Bot updated successfully!");
              this.getBotAccounts();

              this.editing = false; // Reset editing mode

              // Reload the current page
              window.location.reload();
            } else {
              this.$message.error(
                "Failed to update Chat Bot: " + response.message
              );
            }
          } catch (error) {
            console.error(error);
            this.$message.error(
              "An error occurred while updating the Chat Bot."
            );
          } finally {
            this.loading = false;
          }
        })
        .catch(() => {
          // Handle cancellation of the update action
          this.$message.info("Update cancelled.");
        });
    },

    async deleteChatBot() {
      this.$confirm(
        "Are you sure you want to delete this Chat Bot?",
        "Warning",
        {
          type: "warning",
        }
      )
        .then(async () => {
          this.loading = true;
          this.loadingText = "Deleting Chat Bot...";

          try {
            let response = await postAPICall(
              "DELETE",
              `/bot/delete/${this.selectedBotId}`
            );
            if (response && response.success) {
              this.$message.success("Chat Bot deleted successfully!");
              this.getBotAccounts();
              this.showTextArea = false;
              this.editing = false; // Reset editing mode

              // Reload the current page
              window.location.reload();
            } else {
              this.$message.error(
                "Failed to delete Chat Bot: " + response.message
              );
            }
          } catch (error) {
            console.error(error);
            this.$message.error(
              "An error occurred while deleting the Chat Bot."
            );
          } finally {
            this.loading = false;
          }
        })
        .catch(() => {
          // Handle cancellation of the delete action
        });
    },
    cancelEdit() {
      this.showTextArea = false;
      this.newChatBotDetails = ""; // Clear input
      this.selectedBotId = null;
      this.editing = false; // Reset editing flag
    },
  },
};
</script>

<style lang="scss" scoped>
.no-data {
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

/* Scrollbar for textarea */
.scrollable-textarea .el-textarea__inner {
  max-height: 200px;
  overflow-y: auto;
}
</style>
